import {React, useEffect, useState} from 'react'
import { useStateValue } from "../StateProvider";
import { useHistory } from "react-router-dom";
import PlaylistGridItem from './PlaylistGridItem';
import darkMagglass from "../images/darkMagglass.png";

let genre = ""
// let s = null;
function GenrePicker() {
    const [{ spotify, playlistGridArrayData, playlist_type }, dispatch] = useStateValue();
    // const [playlistGridArrayData, setPlaylistGridArrayData] = useState();
    const [error, setError] = useState(false);
    const history = useHistory();
    let playlistGridArrayDataAux = playlistGridArrayData;

    const handlePlaylistSelection = (playlist)  => {
        console.log("Putting in this playlist/album: ", playlist);
        localStorage.setItem('Current Playlist', JSON.stringify(playlist));
        dispatch({
            type: "SET_CURRENT_PLAYLIST",
            current_playlist: playlist
        })
        let path = "/main/player/"
        history.push(path)
    }

    const searchAndRouteToMain = function(){
        let query = genre.split(' ').join('+')
        let playlist;
        console.log("Currently searching and routing to main...")
        console.log("Spotify object currently: " + spotify)
        if (spotify !== null && playlist_type !== null) {
            spotify.search(query, ["playlist", "album"], (err, data) => {
                if (err) {
                    console.log(err)
                    setError(true)
                } else {
                    console.log("Finished searching! Here's the data: ", data)
                    if (data.playlists.items.length === 0) {
                        console.log("No playlists found!")
                    } else {
                        setError(false)
                        if (playlist_type === "playlist") {
                            dispatch({
                                type: "SET_PLAYLIST_GRID_ARRAY_DATA",
                                playlistGridArrayData: data.playlists.items
                            })
                            console.log("just setPlaylistGridArrayData: ", playlistGridArrayData);
                            // playlist = data.playlists.items[0];
                            // console.log("playlistType is playlist. Putting in this playlist: ", playlist);
                        } else {
                            dispatch({
                                type: "SET_PLAYLIST_GRID_ARRAY_DATA",
                                playlistGridArrayData: data.albums.items
                            })
                            console.log("just setPlaylistGridArrayData: ", playlistGridArrayData);
                            // playlist = data.albums.items[0];
                            // console.log("playlistType is album. Putting in this album: ", playlist);
                        }
                        // localStorage.setItem('Current Playlist', JSON.stringify(playlist));
                        // dispatch({
                        //     type: "SET_CURRENT_PLAYLIST",
                        //     current_playlist: playlist
                        // })
                        // let path = "/main/player/"
                        // history.push(path)
                    }
                }
            })
        } else {
            console.log("Rip, Spotify object is null: ", spotify)
        }
    }

    //retrieves global state once it has been saved and sets the local components state
    useEffect(() => {
        if (!playlistGridArrayDataAux) {
            playlistGridArrayDataAux = playlistGridArrayData;
        }  
    }, [playlistGridArrayData])

    const handleQueryChange = (event) => {
        genre = event.target.value;
    }

    function inputKeyUp(e) {
        e.which = e.which || e.keyCode;
        if(e.which == 13) {
            document.getElementById("searchBarSubmit").click();
        }
    }

    const handlePlaylistTypeChange = function(playlistTypeString) {
        dispatch({
            type: "SET_PLAYLIST_TYPE",
            playlist_type: playlistTypeString
        })
    }
    return (
        <div id="genrePickerContainer">
            <h1 id="genreHeader">Explore Page</h1>
            <div id="searchBarContainer">
                <input type="text" placeholder="Search for a genre..." onChange={handleQueryChange} onKeyUp={inputKeyUp}/>
                <button id="searchBarSubmit" type="submit" onClick={searchAndRouteToMain}>
                        <img src={darkMagglass} alt="Search Button"></img>
                </button>
            </div>
            <div id="boxPlaylistTypeSelector">
                <label>
                    <p>Playlist</p>
                    <input type="radio" checked={playlist_type === "playlist"} onChange={() => handlePlaylistTypeChange("playlist")}/>
                </label>
                <label>
                    <p>Album</p>
                    <input type="radio" checked={playlist_type === "album"} onChange={() => handlePlaylistTypeChange("album")}/>
                </label>
            </div>
            {   
                error
                ? <p>No playlists were found for that last search!</p>
                : <p></p> 
            }
            <div id="playlistGrid">
            {
                playlistGridArrayData
                    ? playlistGridArrayDataAux.map((playlist, curIndex) => 
                        <PlaylistGridItem playlist={playlist} onClickFunction={handlePlaylistSelection} key={playlist?.uri + curIndex}/>
                    ) 
                    : <div></div>
            }
            </div>
        </div>
    )
}

export default GenrePicker

